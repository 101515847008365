import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';


import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import SubCard from 'ui-component/cards/SubCard';

import Chart from 'react-apexcharts';

import config, { ThemeMode } from 'config';
import { showPremiumFeatureDialog } from 'actions/planner2';
import { Box, FormControl, FormHelperText, OutlinedInput, TextareaAutosize } from '@material-ui/core';
import { saveContactDetails } from 'api_handlers/brand';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


export default function AdvancedFilters() {
  const open = useSelector((state) => state.planner2.showPremiumFeatureDialog);
  const dispatch = useDispatch();
  const theme = useTheme();

  const [errors, setErrors] = React.useState('');
  const [formInfo, setFormInfo] = React.useState({
    'email': { 'value': '', 'error': false },
    'company_name': { 'value': '', 'error': false },
    'phone_number': { 'value': '', 'error': false }, 
    'reason': { 'value': '', 'error': false }, 
    'first_name': { 'value': '', 'error': false }, 
    'last_name': { 'value': '', 'error': false }, 
  })
  const handleClose = () => dispatch(showPremiumFeatureDialog(false));


  const blockSX = {
    p: 2.5,
    borderLeft: '1px solid ',
    borderBottom: '1px solid ',
    borderLeftColor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'grey.200',
    borderBottomColor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'grey.200'
  };

  const submitContactForm = async () => {
    let tempFormInfo = {...formInfo};
    let isValidationError = false;
    let apiData = {};
    Object.keys(tempFormInfo).map((field)=>{
      let value = tempFormInfo[field]['value'];
      tempFormInfo[field]['error'] = !(value && value.length > 0);
      apiData[field] = value;
      if (!isValidationError && tempFormInfo[field]['error']) {
        isValidationError = tempFormInfo[field]['error'];
      }
    })
    let re = /\S+@\S+\.\S+/;
    if (!re.test(tempFormInfo['email']['value'])) {
      tempFormInfo['email']['error'] = true;
      isValidationError = true;
    }
    let isValidEmail = true;
    let personalDomain = ["gmail.com","yahoo.com","outlook.com","protonmail.com","aol.com","zoho.com","icloud.com","gmx.com","yandex.com","mail.com","google.com"]
    personalDomain.map(each=>{
      if (isValidEmail && tempFormInfo['email']['value'].indexOf(each) > -1) {
        isValidEmail = false;
      }
    })
    if (!isValidEmail) {
      isValidationError = true;
      setErrors({ 'non_field_errors': ["Oops! There seems to be an issue with your email domain please use your work email or write to us at Enquiry@influencer.in and we will get this working for you immediately."] })
    }
    setFormInfo(tempFormInfo);
    if (isValidationError) {
      return
    }
    apiData['phone_number'] = apiData['phone_number'].indexOf('+') === -1 ? 
    '+91'+apiData['phone_number'] : apiData['phone_number'];
    let resp = await saveContactDetails(apiData);
    if ([200, 201].includes(resp.status)) {
      handleClose();
    } else if ([400, 401, 402, 403].includes(resp.status)) { 
      const errors = await resp.json();
      setErrors(errors);
    } else {
      setErrors({ 'non_field_errors': ['Please Try Again'] });
    }
  }

  const setFormInfoValue = (key, value) => {
    let tempFormInfo = {...formInfo}
    tempFormInfo[key]['value'] = value;
    tempFormInfo[key]['error'] = !(value && value.length > 0);
    setFormInfo(tempFormInfo);
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container justifyContent={'center'} alignItems={'center'} spacing={3}>
            <Grid item md={5} style={{ marginLeft: '2rem' }}>
              <Typography style={{ fontWeight: 'bold', fontSize: '2.6rem' }}>Need</Typography>
              <Typography style={{ fontWeight: 'bold', fontSize: '2.6rem' }}>support?</Typography>
              <Typography style={{ fontWeight: 'bold', fontSize: '3rem' }}>Talk to our</Typography>
              <Typography style={{ fontWeight: 'bold', fontSize: '3rem' }} color={'primary'}>Experts</Typography>
              <Typography style={{ fontSize: '1rem', marginTop: '1rem' }}>
                Connect with Our Experienced Product Specialists to Learn How Influencer.in can
                Benefit Your Business!
              </Typography>
              
            </Grid>
            <Grid item md={6} container direction={'column'}>
              <Grid item container spacing={2}>
                <Grid item md={6}>
                  <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={formInfo['first_name']['error']}>
                      <Typography style={{ fontWeight: 'bold', marginBottom: '5px' }}>First Name</Typography>
                      <OutlinedInput
                          id="outlined-adornment-first_name-login"
                          type="text"
                          name="first_name"
                          placeholder="First Name"
                          inputProps={{}}
                          onChange={(e)=>{
                            setFormInfoValue('first_name', e.target.value);
                          }}
                          />
                      {formInfo['first_name']['error'] && (
                          <FormHelperText error id="standard-weight-helper-text-first_name-login">
                            Please Enter Valid First Name
                          </FormHelperText>
                      )}
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={formInfo['last_name']['error']}>
                      <Typography style={{ fontWeight: 'bold', marginBottom: '5px' }}>Last Name</Typography>
                      <OutlinedInput
                          id="outlined-adornment-last_name-login"
                          type="text"
                          name="last_name"
                          placeholder="Last Name"
                          inputProps={{}}
                          onChange={(e)=>{
                            setFormInfoValue('last_name', e.target.value);
                          }}
                          />
                      {formInfo['last_name']['error'] && (
                          <FormHelperText error id="standard-weight-helper-text-last_name-login">
                            Please Enter Valid Last Name
                          </FormHelperText>
                      )}
                    </FormControl>
                </Grid>
              </Grid>
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={formInfo['email']['error']} >
                  <Typography style={{ fontWeight: 'bold', marginBottom: '5px' }}>Work Email Address</Typography>
                  <OutlinedInput
                      id="outlined-adornment-email-login"
                      type="email"
                      name="email"
                      placeholder="Work Email Address"
                      inputProps={{}}
                      onChange={(e)=>{
                        setFormInfoValue('email', e.target.value);
                      }}
                      />
                  {formInfo['email']['error'] && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        Please Enter Valid Email
                      </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={formInfo['company_name']['error']}>
                  <Typography style={{ fontWeight: 'bold', marginBottom: '5px' }}>Company Name</Typography>
                  <OutlinedInput
                      id="outlined-adornment-company-login"
                      type="text"
                      name="company_name"
                      placeholder="Company Name"
                      inputProps={{}}
                      onChange={(e)=>{
                        setFormInfoValue('company_name', e.target.value);
                      }}
                      />
                  {formInfo['company_name']['error'] && (
                      <FormHelperText error id="standard-weight-helper-text-company-login">
                        Please Enter Valid Company Name
                      </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={formInfo['phone_number']['error']}>
                  <Typography style={{ fontWeight: 'bold', marginBottom: '5px' }}>Contact Details</Typography>
                  <OutlinedInput
                      id="outlined-adornment-conatct-login"
                      type="number"
                      name="phone_number"
                      placeholder="Add your 10 digit phone number"
                      inputProps={{}}
                      onChange={(e)=>{
                        setFormInfoValue('phone_number', e.target.value);
                      }}
                      />
                  {formInfo['phone_number']['error'] && (
                      <FormHelperText error id="standard-weight-helper-text-conatct-login">
                        Please Enter Valid Conact Details
                      </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={formInfo['reason']['error']}>
                  <Typography style={{ fontWeight: 'bold', marginBottom: '5px' }}>How Can We Help</Typography>
                  <TextareaAutosize
                      minRows={3}
                      id="outlined-adornment-reason-login"
                      type="text"
                      name="help"
                      placeholder="How Can We Help?"
                      inputProps={{}}
                      onChange={(e)=>{
                        setFormInfoValue('reason', e.target.value);
                      }}
                      />
                  {formInfo['reason']['error'] && (
                      <FormHelperText error id="standard-weight-helper-text-reason-login">
                        Please Enter Valid Reason
                      </FormHelperText>
                  )}
                </FormControl>
              </Box>
              {
                errors ? 
                <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{'non_field_errors' in errors ? errors['non_field_errors'].map(each=>{
                        return each;
                    }) :
                    <ul>
                    {Object.keys(errors).map((key) => {
                      const error = errors[key];
                      if (Array.isArray(error)) {
                        return (
                          <li style={{ fontSize: 12 }}>
                            {error}
                          </li>
                        );
                      } else {
                        return (
                          <li style={{ fontSize: 12 }}>
                            {Object.values(error).join(',')}
                          </li>
                        );
                      }
                    })}
                  </ul>}</FormHelperText>
                </Box> : null
            }
              <Box sx={{ mt: 2 }}>
                <Button onClick={()=>{submitContactForm()}} disableElevation fullWidth size="large" variant="contained" color="secondary">
                    Submit
                </Button>
            </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
